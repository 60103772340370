import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    resetError,
    selectUser,
    selectUserSM,
    connectAsync
} from './userSlice'
// import logo from '../../statics/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLock, faSync, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'

const Login = (props) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const userSM = useSelector(selectUserSM)
    const history = useHistory()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const { size } = props

    useEffect(() => {
        if (userSM.error) {
            setTimeout(() => {
                setUsername('')
                setPassword('')
                dispatch(resetError())
            }, 1000)
        }
    }, [userSM.error, dispatch])

    useEffect(() => {
        if (user.key) {
            // setTimeout(() => )
        }
    }, [user.key])

    const onSubmit = e => {
        e.preventDefault()
        dispatch(connectAsync({ username, password }))
            .then(() => history.push('/dashboard'))
    }

    return (
        <div className='container-full flex col center bg3L rel'>
            <form className='form classic size300 flex col sb bg1L' onSubmit={onSubmit}>
                <h3 className='text3L'>Suivi de production</h3>
                <div className='flex row sb w100 pd5'>
                    <label htmlFor='username'><FontAwesomeIcon className='fa-lg text3L' icon={faUser} /></label>
                    <input
                        name='username'
                        type='text'
                        placeholder='Utilisateur'
                        value={username}
                        required
                        onChange={e => setUsername(e.target.value)}
                    />
                </div>
                <div className='flex row sb w100 pd5'>
                    <label htmlFor='password'><FontAwesomeIcon className='fa-lg text3L' icon={faLock} /></label>
                    <input
                        name='password'
                        type='password'
                        placeholder='Mot de passe'
                        value={password}
                        required
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                {
                    userSM.status === 'pending' ?
                        <FontAwesomeIcon className='text3L h30px mg5' icon={faSync} spin />
                        :
                        userSM.status === 'fulfilled' ?
                            <FontAwesomeIcon className='green1 h30px mg5' icon={faCheck} />
                            :
                            userSM.status === 'rejected' ?
                                <FontAwesomeIcon className='red1 h30px mg5' icon={faTimes} />
                                :
                                <input className='btn light1 mg5' type='submit' value='Login' disabled={userSM.error} />
                }
                <div className='flex row right w100 text1'>
                    <h4 className='h4 pd0 mg0'>ver. 0.0.2</h4>
                </div>
            </form>
            {
                size.height > 500 &&
                <>
                    {/* <img onClick={() => window.open('https://4soft.ch', '_blank')}  className='abs center-x top15 clickable' src={logo} alt='logo' /> */}
                    <img onClick={() => window.open('https://4soft.ch', '_blank')} className='abs center-x top15 clickable' src={'/static/logo.png'} alt='logo' />
                    <h4 className='abs h4 blue2 center-x bot15 w100 text-center'>4Soft Sàrl - All right reserved 2021</h4>
                </>
            }
        </div>
    )
}

export default Login