import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../others/const'
import { getCookie, handleError } from '../others/func'

export const initialState = {
    selProjet: 0,
}

// export const fetchProjets = createAsyncThunk(
//     'dashboard/fetchProjets',
//     async (data) => {
//         const response = await fetch(`${API_URL}/api/projet/`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Token ${data.token}`,
//                 'X-CSRFToken': getCookie('csrftoken')
//             },
//             body: JSON.stringify({
//                 ...data,
//                 toFetch: 'projets'
//             })
//         })
//             .then(handleError)
//             .then(response => response.json())
//             .catch()
//         return response
//     }
// )

const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        setSelProjet: (state, action) => {
            state.selProjet = action.payload
        }
        // resetError: (state) => {
        //     state.sm.status = 'idle'
        //     state.sm.error = null
        // },
        // disconnectSync: (state) => {
        //     state.user.key = null
        // }
    },
    // extraReducers: (builder) => {
    //     builder
    //         //projet
    //         .addCase(fetchProjet.pending, (state) => {
    //             state.projetSM.status = 'pending'
    //             state.projetSM.error = null
    //         })
    //         .addCase(fetchProjet.fulfilled, (state, action) => {
    //             state.projetSM.status = 'fulfilled'
    //             state.projet = action.payload
    //         })
    //         .addCase(fetchProjet.rejected, (state, action) => {
    //             state.projetSM.status = 'rejected'
    //             state.projetSM.error = action.error.message
    //         })
    //         //projets
    //         .addCase(fetchProjets.pending, (state) => {
    //             state.projetsSM.status = 'pending'
    //             state.projetsSM.error = null
    //         })
    //         .addCase(fetchProjets.fulfilled, (state, action) => {
    //             state.projetsSM.status = 'fulfilled'
    //             state.projets = action.payload
    //         })
    //         .addCase(fetchProjets.rejected, (state, action) => {
    //             state.projetsSM.status = 'rejected'
    //             state.projetsSM.error = action.error.message
    //         })
    // }
})

// Actions
export const { setSelProjet } = navSlice.actions

// Selectors
export const selectSelProjet = (state) => state.nav.selProjet

export default navSlice.reducer