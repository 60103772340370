import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../features/auth/userSlice'
import annonceSlice from '../features/dashboard/annonceSlice'
import dashboardSlice from '../features/dashboard/dashboardSlice'
import navSlice from '../features/nav/navSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    dashboard: dashboardSlice,
    nav: navSlice,
    annonce: annonceSlice
  },
  devTools: true
})
