import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../others/const'
import { handleError } from '../others/func'

export const initialState = {
    user: {
        key: null
    },
    sm: {
        status: 'idle',
        error: null
    }
}

export const connectAsync = createAsyncThunk(
    'user/connectAsync',
    async (user) => {
        const response = await fetch(`${API_URL}/api/v1/users/auth/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: user.username,
                password: user.password
            })
        })
            .then(handleError)
            .then(response => response.json())
            .catch()
        return response
    }
)

export const disconnectAsync = createAsyncThunk(
    'user/disconnectAsync',
    async (token) => {
        const response = await fetch(`${API_URL}/api/v1/users/auth/logout/`, {
            method: 'POST',
            headers: {                
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: {}
        })
            .then(handleError)
            .then(response => response.json())
            .catch()
        return response
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetError: (state) => {
            state.sm.status = 'idle'
            state.sm.error = null
        },
        forceDisconnect: state => {
            state.user.key = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(connectAsync.pending, (state) => {
                state.sm.status = 'pending'
                state.sm.error = null
            })
            .addCase(connectAsync.fulfilled, (state, action) => {
                state.sm.status = 'fulfilled'
                state.user.key = action.payload.key
            })
            .addCase(connectAsync.rejected, (state, action) => {
                state.sm.status = 'rejected'
                state.sm.error = action.error.message
            })
            .addCase(disconnectAsync.pending, (state) => {
                state.sm.status = 'pending'
                state.sm.error = null
            })
            .addCase(disconnectAsync.fulfilled, (state, action) => {
                state.sm.status = 'idle'
                state.user.key = null
            })
            .addCase(disconnectAsync.rejected, (state, action) => {
                state.sm.status = 'rejected'
                state.sm.error = action.error.message
                state.user.key = null
            })
    }
})

// Actions
export const { resetError, forceDisconnect } = userSlice.actions

// Selectors
export const selectUser = (state) => state.user.user
export const selectUserSM = (state) => state.user.sm
export const selectUserKey = (state) => state.user.user.key

export default userSlice.reducer