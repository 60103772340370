import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../auth/userSlice'
import { fetchAnnonces, selectAnnonces, selectAnnoncesSM } from './annonceSlice'
import './annonceur.css'
import { selectProjet } from './dashboardSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

const Annonceur = (props) => {
    // Local
    const { size } = props

    // Store
    const dispatch = useDispatch()
    const annonces = useSelector(selectAnnonces)
    const annoncesSM = useSelector(selectAnnoncesSM)
    const user = useSelector(selectUser)
    const projet = useSelector(selectProjet)

    useEffect(() => {
        if (annoncesSM.status === 'idle' && user.key && projet.id > 0) dispatch(fetchAnnonces({ token: user.key, id: projet.id, toFetch: 'annonces' }))
    })

    return (
        <div className={`annonceur-container${size.device}`}>
            {
                annoncesSM.status === 'pending' ?
                    <FontAwesomeIcon spin icon={faSync} className='blue1' style={{ marginTop: '30px' }} />
                    :
                    <>
                        <h3>Annonces</h3>
                        <div className='scroll'>
                            {
                                annonces.map((ann, index) =>
                                    <div key={`ann_${index}`} className='annonce'>
                                        <span className={`datetime${size.device} ${size.width < 700 ? 'datetime-hide' : ''} ${index % 2 === 0 ? 'pair' : 'impair'}`}>{size.width < 900 ? ann.datetime.substring(0, 10) : ann.datetime}</span>
                                        <span className={`desc${size.device} ${index % 2 === 0 ? 'pair' : 'impair'}`}>{ann.desc}</span>
                                    </div>
                                )
                            }
                        </div>
                    </>
            }
        </div>
    )
}

export default Annonceur