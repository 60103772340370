import React, { useState, useEffect } from 'react'
import './app.css'
import Login from '../src/features/auth/Login'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Dashboard from './features/dashboard/Dashboard'
import Nav from './features/nav/Nav'
import Calendrier from './features/calendrier/Calendrier'

function App() {
  const size = useWindowSize()

  return (
    <div className="App">
      <Router>
        <Nav size={size} />
        <Switch>
          <Route path='/' exact>
            <Login size={size} />
          </Route>
          <Route path='/dashboard' exact>
            <Dashboard size={size} />
          </Route>
          <Route path='/calendrier' exact>
            <Calendrier size={size} />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    device: undefined
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      let str, w = window.innerWidth
      if (w < 900) str = '-sm'
      else if (w < 1300) str = '-md'
      else str = ''
      setWindowSize({
        width: w,
        height: window.innerHeight,
        device: str
      })
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export default App
