import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../others/const'
import { getCookie, handleError } from '../others/func'

export const initialState = {
    annonces: [],
    annoncesSM: {
        status: 'idle',
        error: null
    }
}

export const fetchAnnonces = createAsyncThunk(
    'dashboard/fetchAnnonces',
    async (data) => {
        const response = await fetch(`${API_URL}/api/annonce/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${data.token}`,
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify({
                ...data,
                toFetch: 'annonces'
            })
        })
            .then(handleError)
            .then(response => response.json())
            .catch()
        return response
    }
)

const annonceSlice = createSlice({
    name: 'annonce',
    initialState,
    reducers: {
        // resetError: (state) => {
        //     state.sm.status = 'idle'
        //     state.sm.error = null
        // },
        // disconnectSync: (state) => {
        //     state.user.key = null
        // }
    },
    extraReducers: (builder) => {
        builder
            // annonces
            .addCase(fetchAnnonces.pending, (state) => {
                state.annoncesSM.status = 'pending'
                state.annoncesSM.error = null
            })
            .addCase(fetchAnnonces.fulfilled, (state, action) => {
                state.annoncesSM.status = 'fulfilled'
                state.annonces = action.payload
            })
            .addCase(fetchAnnonces.rejected, (state, action) => {
                state.annoncesSM.status = 'rejected'
                state.annoncesSM.error = action.error.message
            })
    }
})

// Actions
// export const {  } = userSlice.actions

// Selectors
export const selectAnnonces = (state) => state.annonce.annonces
export const selectAnnoncesSM = (state) => state.annonce.annoncesSM

export default annonceSlice.reducer