import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import '../others/calendrier.css'
import { useSelector } from 'react-redux'
import { selectTaches } from '../dashboard/dashboardSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronLeft, faChevronRight, faPause, faSync } from '@fortawesome/free-solid-svg-icons'

const Calendrier = (props) => {
    // Store
    const taches = useSelector(selectTaches)

    //Local
    const { size } = props
    const [mois, setMois] = useState(new Date().getMonth())
    const [annee, setAnnee] = useState(new Date().getFullYear())
    const [firstTaskIndex, setFirstTaskIndex] = useState(0)
    const today = moment()
    const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
    const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
    const colors = ['#2ecc71', '#f1c40f', '#e74c3c', '#4d908eff', '#577590ff', '#277da1ff', '#3498db', '#f9c74fff', '#90be6dff', '#43aa8bff']

    const changeMonth = add => {
        let newMois, newAnnee = annee
        if (add) {
            if (mois < 11) newMois = mois + 1
            else {
                newMois = 0
                newAnnee = annee + 1
            }
        }
        else {
            if (mois > 0) newMois = mois - 1
            else {
                newMois = 11
                newAnnee = annee - 1
            }
        }
        setMois(newMois)
        setAnnee(newAnnee)
    }

    // const createCalendar = () => {
    //     let cal = []
    //     let week = []
    //     let k = 1
    //     let startOfMonth = moment().set({ 'year': annee, 'month': mois }).startOf('month').day()
    //     if (startOfMonth === 0) startOfMonth = 7
    //     let endOfMonth = moment().set({ 'year': annee, 'month': mois }).endOf('month').day()
    //     let startOfCalendar = moment().set({ 'year': annee, 'month': mois }).startOf('month').subtract(startOfMonth - 1, 'days')
    //     let endOfCalendar = moment().set({ 'year': annee, 'month': mois }).endOf('month').add(7 - endOfMonth, 'days')
    //     for (let day = startOfCalendar; day < endOfCalendar; day.add(1, 'days')) {
    //         let d = moment(day.format('DD-MM-YYYY'), 'DD-MM-YYYY')
    //         week.push(d)
    //         if (day.day() === 0 && k > 6) {
    //             cal.push([...week])
    //             week = []
    //         }
    //         k++
    //     }
    //     return cal
    // }

    const createCalendarTaches = () => {
        let cal = []
        let week = []
        let k = 1
        let tmp
        let startOfMonth = moment().set({ 'year': annee, 'month': mois }).startOf('month').day()
        let ft = true
        if (startOfMonth === 0) startOfMonth = 7
        let endOfMonth = moment().set({ 'year': annee, 'month': mois }).endOf('month').day()
        let startOfCalendar = moment().set({ 'year': annee, 'month': mois }).startOf('month').subtract(startOfMonth - 1, 'days')
        let endOfCalendar = moment().set({ 'year': annee, 'month': mois }).endOf('month').add(7 - endOfMonth, 'days')
        for (let day = startOfCalendar, i = 0; day < endOfCalendar; day.add(1, 'days'), i++) {
            let d = moment(day.format('DD-MM-YYYY'), 'DD-MM-YYYY')
            let add = {}
            let tasks = []
            // taches.forEach((tache, index) => {
            for (const [index, tache] of taches.entries()) {
                if (d.isBetween(moment(tache.debut, 'DD-MM-YYYY'), moment(tache.fin, 'DD-MM-YYYY'), undefined, '[]')) {
                    if (ft) {
                        tmp = i
                        ft = false
                    }
                    tasks.push([tasks.length, colors[index]])
                }
            }
            add.date = d
            add.add = tasks
            week.push(add)
            if (day.day() === 0 && k > 6) {
                cal.push([...week])
                week = []
            }
            k++
        }
        if (tmp !== firstTaskIndex) setFirstTaskIndex(tmp)
        return cal
    }

    const tacheRow = (add, index) => {
        return (
            <div key={`task${add[0]}`} className='tache-row' style={{ backgroundColor: add[1], bottom: `${add[0] * 10}px`, animation: `heightTo20 .1s forwards ${0.1 * index}s` }} />
        )
    }

    const tooltipStatus = etat => {
        switch (etat) {
            case 0:
                return 'En attente'
            case 1:
                return 'En cours'
            case 2:
                return 'Terminé'
            default:
                return ''
        }
    }

    useCalendarCaseSize()

    return (
        <div className='flex col start bg3L container-full'>
            <div className={`flex ${size.width < 900 ? 'col-r' : 'row'} left w100 pdt80`}>
                <div className={`flex col center calendar-infos${size.device}`}>
                    <h2 className={`h2${size.device} calendar-taches-title`}>Tâches</h2>
                    <div className='calendrier-taches'>
                        {
                            taches.map((tache, index) =>
                                <div key={`tache_${tache.id}_${index}`} className='tache-container'>
                                    <h2  className={`h2${size.device}`} data-tip data-for={`tache_${tache.id}`} style={{ backgroundColor: colors[index] }}>{tache.nom}</h2>
                                    <ReactTooltip id={`tache_${tache.id}`} type='dark' place='right'>
                                        <span>{tache.desc}</span>
                                    </ReactTooltip>
                                    {
                                        tache.sousTaches.map((st, stInd) =>
                                            <div key={`sts_${stInd}`} className='sous-tache' data-tip data-for={`soustache_${st.id}`}>
                                                <ReactTooltip id={`soustache_${st.id}`} type='dark' place='right' multiline={true}>
                                                    <span>{`${tooltipStatus(st.etat)} - ${st.desc}`}</span>
                                                </ReactTooltip>
                                                <h3 key={`st${st.id}_${stInd}`} className={`h3${size.device}`}>{st.nom}</h3>
                                                <div className='sous-tache-etat'>
                                                    {
                                                        st.etat === 0 ?
                                                            <FontAwesomeIcon className='red1' icon={faPause} />
                                                            :
                                                            st.etat === 1 ?
                                                                <FontAwesomeIcon spin className='yellow1' icon={faSync} />
                                                                :
                                                                <FontAwesomeIcon className='green1' icon={faCheck} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='flex col w90 calendrier'>
                    <div className='flex row center w100'>
                        <button type='button' className='btn2 bg3L' onClick={() => changeMonth(false)}><FontAwesomeIcon icon={faChevronLeft} /></button>
                        <h2 className={`h2${size.device}`} style={{ width: '250px', textAlign: 'center' }}>{`${months[mois]} ${annee}`}</h2>
                        <button type='button' className='btn2 bg3L' onClick={() => changeMonth(true)}><FontAwesomeIcon icon={faChevronRight} /></button>
                    </div>
                    <div className='flex row'>
                        {
                            days.map((day, index) =>
                                <div key={`jour_${day}_${index}`} className='header'>
                                    {size.width < 900 ? day[0] : size.width < 1300 ? day.substring(0, 3) : day}
                                </div>
                            )
                        }
                    </div>
                    {
                        createCalendarTaches(mois, annee).map((week, index) =>
                            <div key={`row_cal_${index}`} className={`flex row calendrier-row`}>
                                {
                                    week.map((moment, index2) =>
                                        <div key={`calendrier${moment.date.date()}${index2}`}
                                            className={`calendrier-case${moment.date.month() !== mois ? ' not-month' : ''}${size.width < 900 ? ' small' : ''}`}
                                        >
                                            <p
                                                className={`day-btn${today.isSame(moment.date, 'day') ? ' today' : ''}`}
                                            >
                                                {moment.date.date()}
                                            </p>
                                            {
                                                moment.add.map(a => tacheRow(a, index * 7 + index2 - firstTaskIndex))
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

function useCalendarCaseSize() {
    const [calendarCaseSize, setCalendarCaseSize] = useState({
        width: undefined,
        height: undefined,
    })

    useEffect(() => {
        // Handler to call on window resize
        function handleResizeCalendar() {
            const divCase = document.getElementsByClassName('calendrier-case')
            if (divCase) {
                // Set window width/height to state
                document.documentElement.style.setProperty('--cal-width', divCase[0].clientWidth + 'px')
                setCalendarCaseSize({
                    width: divCase[0].clientWidth,
                    height: divCase[0].clientHeight,
                })
            }
        }
        window.addEventListener("resize", handleResizeCalendar)
        handleResizeCalendar()
        return () => window.removeEventListener("resize", handleResizeCalendar)
    }, [])

    return calendarCaseSize
}

export default Calendrier