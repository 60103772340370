import React, { useEffect, useState } from 'react'
import ProgressBar from 'progressbar.js'
import './compteur.css'

const Compteur = props => {
    const { data } = props
    const [bar, setBar] = useState(null)

    useEffect(() => {
        if (document.getElementById(data.id) && !bar && data.total && data.value) {
            setBar(new ProgressBar.Circle(document.getElementById(data.id), {
                strokeWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                color: '#3A7EC5',
                // text: {
                //     position: "absolute",
                //     left: "50%",
                //     top: "50%",
                //     padding: "0px",
                //     margin: "0px",
                //     transform: "translate(-50%, -50%)",
                //     color: "#333",                     
                // },
                trailColor: '#000',
                trailWidth: 0.1,
                svgStyle: null,
                step: function (state, circle) {
                    let value = Math.round(circle.value() * data.total)
                    circle.setText(`${value}/${data.total}<br /><br />${Math.round(circle.value() * 100)}%`)
                }
            }))
        }
        if (bar) bar.animate(data.value / data.total)
    }, [data.id, data.total, data.value, bar])

    useEffect(() => {        
        if (bar) {
            document.getElementById(data.id).innerHTML = ''
            setBar(null)
        }
    }, [data.value, data.total, data.id])

    return (
        <div className='container-compteur'>
            <h3>{data.titre}</h3>
            <div className='container-compteur-inner'>
                <div id={data.id} className='compteur' />
            </div>
        </div>
    )
}

export default Compteur