import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../others/const'
import { getCookie, handleError, wait } from '../others/func'

export const initialState = {
    projet: {},
    taches: [],
    projetSM: {
        status: 'idle',
        error: null
    },
    projets: [],
    projetsSM: {
        status: 'idle',
        error: null
    }
}

export const fetchProjet = createAsyncThunk(
    'dashboard/fetchProjet',
    async (data) => {
        const response = await fetch(`${API_URL}/api/projet/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${data.token}`,
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify({
                ...data,
                toFetch: 'projet'
            })
        })
            .then(handleError)
            .then(response => response.json())
            .catch()
        return response
    }
)

export const fetchProjets = createAsyncThunk(
    'dashboard/fetchProjets',
    async (data) => {
        const response = await fetch(`${API_URL}/api/projet/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${data.token}`,
                'X-CSRFToken': getCookie('csrftoken')
            },
            body: JSON.stringify({
                ...data,
                toFetch: 'projets'
            })
        })
            .then(handleError)
            .then(response => response.json())
            .catch()
        return response
    }
)

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        // resetError: (state) => {
        //     state.sm.status = 'idle'
        //     state.sm.error = null
        // },
        // disconnectSync: (state) => {
        //     state.user.key = null
        // }
    },
    extraReducers: (builder) => {
        builder
            //projet
            .addCase(fetchProjet.pending, (state) => {
                state.projetSM.status = 'pending'
                state.projetSM.error = null
            })
            .addCase(fetchProjet.fulfilled, (state, action) => {
                state.projetSM.status = 'fulfilled'
                state.projet = action.payload.projet
                state.taches = action.payload.taches
            })
            .addCase(fetchProjet.rejected, (state, action) => {
                state.projetSM.status = 'rejected'
                state.projetSM.error = action.error.message
            })
            //projets
            .addCase(fetchProjets.pending, (state) => {
                state.projetsSM.status = 'pending'
                state.projetsSM.error = null
            })
            .addCase(fetchProjets.fulfilled, (state, action) => {
                state.projetsSM.status = 'fulfilled'
                state.projets = action.payload
            })
            .addCase(fetchProjets.rejected, (state, action) => {
                state.projetsSM.status = 'rejected'
                state.projetsSM.error = action.error.message
            })
    }
})

// Actions
// export const {  } = userSlice.actions

// Selectors
export const selectProjet = (state) => state.dashboard.projet
export const selectTaches = (state) => state.dashboard.taches
export const selectProjetSM = (state) => state.dashboard.projetSM
export const selectProjets = (state) => state.dashboard.projets
export const selectProjetsSM = (state) => state.dashboard.projetsSM

export default dashboardSlice.reducer