import React, { useEffect } from 'react'
import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../auth/userSlice'
import { selectSelProjet, setSelProjet } from '../nav/navSlice'
import {
    selectProjet,
    fetchProjet,
    fetchProjets,
} from './dashboardSlice'
import Compteur from './Compteur'
import Annonceur from './Annonceur'
import { fetchAnnonces } from './annonceSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'


const Dashboard = props => {

    // Local
    const { size } = props

    // UseState
    const [nbJoursRestant, setNbJoursRestant] = useState(0)
    const [nbJoursTotaux, setNbJoursTotaux] = useState(0)
    const [nbSousTaches, setNbSousTaches] = useState(0)
    const [nbSousTachesDone, setNbSousTachesDone] = useState(0)

    // Store
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const selProjet = useSelector(selectSelProjet)
    const projet = useSelector(selectProjet)

    useEffect(() => {
        dispatch(fetchProjets({ token: user.key }))
            .then(res => {
                if (res.payload && res.payload.length > 0) dispatch(setSelProjet(res.payload[0].id))
            })
    }, [])

    const resetCircles = payload => {
        // circle loadings
        if (payload) {
            let lastDay = null
            let firstDay = null
            let st = 0
            let stDone = 0
            payload.taches.forEach((tache, index) => {
                if (index === 0) {
                    lastDay = moment(tache.fin, 'DD-MM-YYYY')
                    firstDay = moment(tache.debut, 'DD-MM-YYYY')
                }
                else {
                    if (lastDay.isBefore(moment(tache.fin, 'DD-MM-YYYY'))) lastDay = moment(tache.fin, 'DD-MM-YYYY')
                    if (firstDay.isAfter(moment(tache.debut, 'DD-MM-YYYY'))) firstDay = moment(tache.debut, 'DD-MM-YYYY')
                }
                let subStDone = 0
                tache.sousTaches.forEach(sTache => {
                    if (sTache.etat === 2) subStDone += 1
                })
                st += tache.sousTaches.length
                stDone += subStDone
            })
            setNbJoursRestant(lastDay.diff(moment(), 'days'))
            setNbJoursTotaux(lastDay.diff(firstDay, 'days'))
            setNbSousTaches(st)
            setNbSousTachesDone(stDone)
        }
    }

    useEffect(() => {
        if (selProjet > 0) {
            dispatch(fetchProjet({ token: user.key, id: selProjet }))
                .then(res => resetCircles(res.payload))
            dispatch(fetchAnnonces({ token: user.key, id: selProjet, toFetch: 'annonces' }))
        }
    }, [selProjet])

    return (
        <div className='flex col start bg3L container-full pdcontainer5'>
            <div className='flex row center wrap w100 pdt120'>
                <Compteur data={{ id: 'progression', titre: 'Progression', value: nbSousTachesDone, total: nbSousTaches }} />
                <Compteur data={{ id: 'nbjoursrestant', titre: 'Nombre de jours', value: nbJoursTotaux - nbJoursRestant, total: nbJoursTotaux }} />
                <div className='container-compteur'>
                    <h3>Travaux</h3>
                    <div className='flex col center w60' style={{ height: '60px'}}>
                        <div className='flex row sb w100'>
                            <span style={{ color: "#333" }}>Début</span>
                            <span style={{ color: "#333" }}>{projet.debut}</span>
                        </div>
                        <div className='flex row sb w100'>
                            <span style={{ color: "#333" }}>Fin</span>
                            <span style={{ color: "#333" }}>{projet.fin}</span>
                        </div>
                    </div>
                    <h3>Interface</h3>
                    <div className='flex col center'>
                        {projet.intDispo ? <a href={projet.intUrl} target='_blank'><FontAwesomeIcon size='2x' className='green1' icon={faHome} /></a> : <h3 className={`h3${size.device} red1`}>Non disponible</h3>}
                    </div>
                </div>
            </div>
            <Annonceur size={size} />
        </div>
    )
}

export default Dashboard