import { useRef, useEffect } from 'react'

export const useIsFirstMount = () => {
    const isMountRef = useRef(true)
    useEffect(() => {
        isMountRef.current = false
    }, [])
    return isMountRef.current
}

export const handleError = (response) => {
    if (!response.ok) {
        throw Error(response.statusText)
    }
    return response
}

export function getCookie(name) {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                break
            }
        }
    }
    return cookieValue
}

export function wait(ms) {
    var start = Date.now(),
        now = start
    while (now - start < ms) {
      now = Date.now()
    }
}