import { faCalendarAlt, faChartLine, faPowerOff, faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { disconnectAsync, selectUser, selectUserSM } from '../auth/userSlice'
import { selectProjets, selectProjetsSM } from '../dashboard/dashboardSlice'
import './nav.css'
import { selectSelProjet, setSelProjet } from './navSlice'

const Nav = (props) => {
    const { size } = props
    // Store
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const userSM = useSelector(selectUserSM)
    const projets = useSelector(selectProjets)
    const projetsSM = useSelector(selectProjetsSM)
    const selProjet = useSelector(selectSelProjet)

    // Others hooks
    const history = useHistory()

    // Others const
    const isPending = userSM.status === 'pending'

    useEffect(() => {
        if (!user.key) history.push('/')
    })

    const disconnect = (token) => {
        dispatch(disconnectAsync(token))
    }

    const changeProjet = value => {
        let id = parseInt(value)
        if (Number.isInteger(id)) dispatch(setSelProjet(id))
    }

    return (
        <nav style={user.key ? {} : { display: 'none' }}>
            <div className='flex row projets'>
                {size.width < 900 ? null : <h3>Projets</h3>}
                {
                    projetsSM.status === 'pending' ?
                        <FontAwesomeIcon spin icon={faSync} />
                        :
                        <select style={{ maxWidth: `${size.width < 900 ? '80px' : ''}` }}
                            value={selProjet}
                            onChange={e => changeProjet(e.target.value)}>
                            {projets.map((projet, index) => <option value={projet.id} key={`projetSelect_${projet.id}_${index}`}>{projet.nom}</option>)}
                        </select>
                }
            </div>
            <NavLink to='/dashboard' className={`${size.width < 900 ? 'small' : ''}`} activeClassName='active'>{size.width < 900 ? <FontAwesomeIcon icon={faChartLine} /> : 'Dashboard'}</NavLink>
            <NavLink to='/calendrier' className={`${size.width < 900 ? 'small' : ''}`} activeClassName='active'>{size.width < 900 ? <FontAwesomeIcon icon={faCalendarAlt} /> : 'Calendrier'}</NavLink>
            {/* <button type='button' onClick={() => console.log(user.key)} style={{position: 'relative'}}>Test</button> */}
            <button type='button' onClick={() => disconnect(user.key)}><FontAwesomeIcon className={`${isPending ? 'text3L' : ''}`} spin={isPending} icon={isPending ? faSync : faPowerOff} /></button>
        </nav>
    )
}

export default Nav